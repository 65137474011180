import React from "react";

import p5 from "p5";

interface VoronoiDiagramProps {
  locations: { x: number; y: number }[];
  container?: Element;
}

// https://dev.to/christiankastner/integrating-p5-js-with-react-i0d
// TODO: lets render out our locations to the canvas first
// TODO: we will try to implement fortunes algorithm https://en.wikipedia.org/wiki/Fortune%27s_algorithm

class VoronoiDiagram extends React.Component<VoronoiDiagramProps> {
  node_reference: any;
  virtual_p5: p5 | undefined;
  sketch: any;
  constructor(props: VoronoiDiagramProps) {
    super(props);
    this.node_reference = React.createRef();
  }

  build_sketch() {
    if (!this.props || this.props.locations.length < 1) return;

    this.sketch = (p: p5) => {
      p.setup = () => {
        if (this.props.container) {
          p.createCanvas(
            this.props.container.clientWidth,
            this.props.container.clientHeight
          );
          p.stroke("red");
          p.strokeWeight(6); // Make the points 6 pixels in size
        }
      };

      p.draw = () => {
        p.clear();
        this.props.locations.forEach((location) => {
          p.point(location.x, location.y);
        });
      };
    };
    this.virtual_p5 = new p5(this.sketch, this.node_reference.current);
  }

  componentDidMount() {
    window.addEventListener("resize", this.build_sketch);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.build_sketch);
  }

  componentDidUpdate() {
    this.build_sketch();
  }

  render() {
    return (
      <div className="voronoi-diagram">
        <div ref={this.node_reference}></div>
      </div>
    );
  }
}

export default VoronoiDiagram;
