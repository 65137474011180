import { latLongToPixel, getTopLeftPixelCoords } from "./helpers";
import { VoronoiLocation } from "./types/api";

export const getWorldCoordinates = (
  locations: VoronoiLocation[],
  bounds: google.maps.LatLngBounds,
  zoom: number
): { x: number; y: number }[] => {
  // const zoom = map.getZoom();
  // const bounds = map.getBounds();
  // if (!bounds) return;

  var top_left_screen_coords = getTopLeftPixelCoords(bounds, zoom);
  var world_screen_coords = locations
    .map((location) =>
      latLongToPixel({ lat: location.Lat, lng: location.Long }, zoom)
    )
    .map((world_coords) => {
      return {
        x: world_coords.x - top_left_screen_coords.x,
        y: world_coords.y - top_left_screen_coords.y,
      };
    });

  return world_screen_coords;
};
