import React from "react";
import LocationsContainer from "./locations-container";

interface UserLocationContainerState {
  user_location?: { lat: number; lng: number };
  country?: string;
}

// TODO: test this shit works when deployed...
// const requestUserCountry = async (): Promise<string> => {
//   if (process.env.NODE_ENV === "development") return "Australia";
//   const response = await fetch(process.env.REACT_APP_GEOLOCATION_API_URL!, {
//     method: "GET",
//     headers: { "Content-Type": "application/json" },
//   });
//   const data = await response.json();
//   console.log({ response });
//   console.log({ data });
//   return data["country_name"];
// };

class UserLocationContainer extends React.Component<
  {},
  UserLocationContainerState
> {
  componentDidMount() {
    if (navigator.geolocation) {
      const getUserLocation = (component: React.Component) => {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            component.setState({
              ...component.state,
              user_location: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            });
          },
          function () {
            console.log("Failed to retrieve browser location :c");
          }
        );
      };
      getUserLocation(this);
    }

    // requestUserCountry().then((country) => {
    //   this.setState({ ...this.state, country });
    // });
  }
  render() {
    if (this.state && this.state.user_location) {
      return <LocationsContainer user_location={this.state.user_location} />;
    } else {
      return (
        <div className="user-location-prompt">
          <p className="bold">
            This site requires your location to generate your personalised
            experience.
          </p>
          <p>
            Please accept your browser's prompt for location access in order to
            continue.
          </p>
        </div>
      );
    }
  }
}

export default UserLocationContainer;
