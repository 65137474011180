import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { VoronoiLocation } from "../types/api";

import { getWorldCoordinates } from "../map-generation";
import VoronoiDiagram from "./voronoi-diagram";

interface VornoiMapProps {
  locations: VoronoiLocation[];
}

interface VoronoiMapState {
  map?: google.maps.Map<Element>;
  voronoi_created: boolean;
  world_coords: { x: number; y: number }[];
}

const container_style = {
  width: "100%",
  height: "100%",
};

// starting values
const centre = {
  lat: -31.9797894,
  lng: 115.8525145,
};
const DEFAULT_ZOOM = 13;

// https://stackoverflow.com/questions/5471848/how-to-get-screen-xy-from-google-maps-v3-latlng
// https://react-google-maps-api-docs.netlify.app/#!/GoogleMap

class VoronoiMap extends React.Component<VornoiMapProps, VoronoiMapState> {
  constructor(props: VornoiMapProps) {
    super(props);
    this.state = {
      voronoi_created: false,
      world_coords: [],
    };
  }
  generateMap(map: google.maps.Map<Element>): void {
    if (!this.props.locations || this.props.locations.length === 0) return;
    if (!map) return;
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    if (!bounds) return;

    var world_screen_coords = getWorldCoordinates(
      this.props.locations,
      bounds,
      zoom
    );

    console.log(
      `Creating voronoi (${world_screen_coords.length} points @ zoom: ${zoom})`,
      { world_screen_coords }
    );
    this.setState({
      ...this.state,
      world_coords: world_screen_coords,
      voronoi_created: true,
    });
  }
  componentDidMount() {
    setInterval(() => {
      if (this.state && !this.state.voronoi_created) {
        this.generateMap(this.state.map as google.maps.Map<Element>);
      }
    }, 1000);
  }
  render() {
    return (
      <div className="voronoi-map">
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <div className="layer top">
            <VoronoiDiagram
              locations={this.state.world_coords}
              container={this.state.map?.getDiv()}
            ></VoronoiDiagram>
          </div>
          <div className="layer bottom">
            {/* TODO: while dragging, fade the diagram out to show the map */}
            <GoogleMap
              mapContainerStyle={container_style}
              center={centre}
              zoom={DEFAULT_ZOOM}
              onLoad={(map) => {
                this.setState({ ...this.state, map });
              }}
              onIdle={() => {
                if (this.state && this.state.voronoi_created)
                  this.generateMap(this.state.map as google.maps.Map<Element>);
              }}
              options={{
                mapTypeId: "satellite",
                mapTypeControlOptions: {
                  mapTypeIds: [],
                },
                streetViewControl: false,
                fullscreenControl: false,
                minZoom: 4,
                maxZoom: 17,
              }}
            ></GoogleMap>
          </div>
        </LoadScript>
      </div>
    );
  }
}

export default VoronoiMap;
