import React from "react";
import VoronoiMap from "./voronoi-map";
import { VoronoiLocation } from "../types/api";

interface LocationsContainerState {
  locations: VoronoiLocation[];
  errorMessage?: any;
}

interface LocationsContainerProps {
  user_location: { lat: number; lng: number };
}

const getLocations = async (country: string): Promise<VoronoiLocation[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/locations?country=${country}`,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.json();
};

const sendLocation = async (
  country: string,
  user_location: { lat: number; lng: number }
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/locations`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        latitude: user_location.lat,
        longitude: user_location.lng,
        country,
      }),
    }
  );
  return response.json();
};

class LocationsContainer extends React.Component<
  LocationsContainerProps,
  LocationsContainerState
> {
  constructor(props: LocationsContainerProps) {
    super(props);
    this.state = {
      locations: [],
      errorMessage: null,
    };
  }

  componentDidMount() {
    // https://geolocation-db.com/json
    // TODO: our geolocation API doesnt have CORS enabled, so it wont work in the front-end. Need to consider something else...
    // Just perform it on the server side?
    // For now, lets just hard code in australia...

    let country = "Australia";
    getLocations(country).then((data) => {
      this.setState({ locations: data });
    });

    sendLocation(country, this.props.user_location).then((user_location) => {
      console.log({ user_location });
      // TODO: add user location to our state's location array
    });
  }
  render() {
    return (
      <div className="voronoi-container">
        <VoronoiMap locations={this.state.locations} />
        {this.state && this.state.errorMessage != null && (
          <div>Error: {this.state.errorMessage}</div>
        )}
      </div>
    );
  }
}

export default LocationsContainer;
