// export module helpers {
// https://developers.google.com/maps/documentation/javascript/examples/map-coordinates

// The mapping between latitude, longitude and pixels is defined by the web
// mercator projection.
export const latLongToWorld = (coords: { lat: number; lng: number }) => {
  var siny = Math.sin((coords.lat * Math.PI) / 180);
  var TILE_SIZE = 256;

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return {
    x: TILE_SIZE * (0.5 + coords.lng / 360),
    y: TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  };
};

export const worldToPixel = (world: { x: number; y: number }, zoom: number) => {
  var scale = 1 << zoom;
  return {
    x: Math.floor(world.x * scale),
    y: Math.floor(world.y * scale),
  };
};

export const latLongToPixel = (
  coords: { lat: number; lng: number },
  zoom: number
) => {
  return worldToPixel(latLongToWorld(coords), zoom);
};

export const getTopLeftPixelCoords = (
  bounds: google.maps.LatLngBounds,
  zoom: number
) => {
  return latLongToPixel(
    {
      lat: bounds.getNorthEast().lat(),
      lng: bounds.getSouthWest().lng(),
    },
    zoom
  );
};
